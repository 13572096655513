import clsx from 'clsx';
import * as React from 'react';

import { SVGIcon } from '@components/SVGIcon';
import { uniqueId } from '@helper/uniqueId';

import * as styles from './styles.module.scss';

interface SublistElementProps {
	iconName?: string;
	headline: string;
	text?: string;
}

export const SublistElement: React.FunctionComponent<SublistElementProps> = ({ iconName, headline, text }) => (
	<div key={`${headline}-${uniqueId()}`} className={clsx(styles.listElementGridItem, iconName && styles.withIcon)}>
		{iconName &&
			<div className={styles.icon}>
				<SVGIcon name={iconName as any} />
			</div>
		}
		{headline && <h4>{headline}</h4>}
		{text && <p>{text}</p>}
	</div>
);
