
/* global Content */
import { Link } from 'gatsby';
import * as React from 'react';

import { SVGIcon } from '@components/SVGIcon';

import * as styles from './styles.module.scss';

interface FooterProps extends Content.FooterObject {}

export const Footer: React.FunctionComponent<FooterProps> = ({ operating, socials, areas }) => (
	<div className={styles.footerBackdrop}>
		<footer className={styles.footer}>
			<div className={styles.footerElement}>
				<div className={styles.footerLogo}>
					<div>
						Zahnarztpraxis im Millennium Tower
					</div>
				</div>
				{operating &&
					<div className={styles.footerInfo}>
						<span>{operating.name}</span>
						<span>{operating.address}</span>
					</div>
				}
				{socials &&
					<div className={styles.footerSocials}>
						{Object.entries(socials).map(([provider, account]) => <a key={provider} href={account}><SVGIcon name={provider as any} /></a>)}
					</div>
				}
			</div>
			{areas.map(({ headline, links }) => (
				<div key={headline} className={styles.footerElement}>
					<h5>{headline}</h5>
					{links.map(({ href, title, ...restProps }) => {
						if ((/^\/(?!\/)/u).test(href)) {
							return <Link key={`${href}-${title}-${headline}`} to={href}>{title}</Link>;
						}
						return <a key={`${href}-${title}-${headline}`} href={href} {...restProps}>{title}</a>;
					})}
				</div>
			))}
		</footer>
	</div>
);
