/* global Content */
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import * as React from 'react';
// eslint-disable-next-line import/no-unassigned-import
import 'pure-react-carousel/dist/react-carousel.es.css';

import { Card } from '@components/Card';
import { uniqueId } from '@helper/uniqueId';

import * as styles from './styles.module.scss';

interface CarouselProps {
	slides: {
		image: Content.ImageObject;
		headline: string;
		text: string;
	}[];
}

export const Carousel: React.FunctionComponent<CarouselProps> = ({ slides }) => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.777777)
						}
					}
				}
			}
		}
	`);

	return (
		<CarouselProvider
			naturalSlideWidth={1024}
			naturalSlideHeight={576}
			totalSlides={3}
			visibleSlides={1}
			infinite={true}
			isIntrinsicHeight={true}
			isPlaying={true}
			interval={5000}
		>
			<div className={styles.carouselBackdrop}>
				<div className={styles.carouselWrapper}>
					<Slider>
						{slides.map(({ headline, text, image }) => {
							const source = getImage(files.allFile.edges.find((imageEntry: any) => imageEntry.node.name === image.imageName).node);

							return (
								<Slide key={uniqueId()} index={1}>
									<div className={styles.carouselSlide}>
										{source && <GatsbyImage className={styles.carouselBackground} image={source} alt="" />}
										<Card className={styles.carouselCard} frame={true}>
											<h4>{headline}</h4>
											<h5>{text}</h5>
										</Card>
									</div>
								</Slide>
							);
						})}
					</Slider>
					<DotGroup className={styles.carouselDots} />
				</div>
			</div>
		</CarouselProvider>
	);
};
