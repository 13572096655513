
/* global Content */
import * as React from 'react';

import { Wrapper } from '@components/Wrapper';
import { uniqueId } from '@helper/uniqueId';

import { Image } from './Image';
import * as styles from './styles.module.scss';

interface TwoColImagesProps {
	headline: string;
	subline: string;
	text: string;

	images: [Content.ImageObject, Content.ImageObject];
}

export const TwoColImages: React.FunctionComponent<TwoColImagesProps> = ({ headline, subline, text, images }) => (
	<Wrapper>
		<div className={styles.twoColImages}>
			<div>
				<h4>{subline}</h4>
				<h2>{headline}</h2>
				<p>{text}</p>
			</div>
			{images.map((imageProps) => <Image key={uniqueId()} image={imageProps} />)}
		</div>
	</Wrapper>
);
