import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import * as React from 'react';
// eslint-disable-next-line import/no-unassigned-import
import 'pure-react-carousel/dist/react-carousel.es.css';

import { Card } from '@components/Card';
import { Wrapper } from '@components/Wrapper';
import { uniqueId } from '@helper/uniqueId';
import { useWindowDimensions } from '@hooks/useWindowDimensions';

import * as styles from './styles.module.scss';

interface CardCarouselProps {
	testemonials: {
		reviewerName: string;
		reviewerText: string;
	}[];
}

export const CardCarousel: React.FunctionComponent<CardCarouselProps> = ({ testemonials }) => {
	const [slideCount, setSlideCount] = React.useState(1);

	useWindowDimensions(({ width }) => {
		if (width > 1024) {
			setSlideCount(3);
		}
		else if (width > 767) {
			setSlideCount(2);
		}
		else {
			setSlideCount(1);
		}
	});

	return (
		<CarouselProvider
			naturalSlideWidth={1024}
			naturalSlideHeight={576}
			totalSlides={testemonials.length}
			visibleSlides={slideCount}
			infinite={true}
			isIntrinsicHeight={true}
			isPlaying={true}
			interval={5000}
		>
			<Wrapper>
				<Slider>
					{testemonials.map(({ reviewerName, reviewerText }, index) => (
						<Slide key={`${reviewerText}-${uniqueId()}`} index={index}>
							<Card className={styles.card} frame={true}>
								<p>{reviewerText}</p>
								<span>{reviewerName}</span>
							</Card>
						</Slide>
					))}
				</Slider>
			</Wrapper>
		</CarouselProvider>
	);
};
