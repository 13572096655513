/* global Content */
import * as React from 'react';

import { BackgroundImage } from './BackgroundImage';
import { TwoColumns } from './TwoColumns';

interface HeroProps {
	headline: string;
	text: string;
	ctaText?: string;
	layout: 'twoCols'|'backgroundImage';

	onClick? (): void;
}

export interface WithVideo extends HeroProps {
	video: Content.VideoObject;
}

export interface WithImage extends HeroProps {
	image: Content.ImageObject;
}

export const Hero: React.FunctionComponent<WithVideo | WithImage> = (props) => {
	switch (props.layout) {
		case 'backgroundImage':
			return <BackgroundImage {...props} />;
		case 'twoCols':
			return <TwoColumns {...props} />;
		default:
			return <div>No layout option set</div>;
	}
};
