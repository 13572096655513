import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { uniqueId } from '@helper/uniqueId';

interface SEOProps {}

export const SEO: React.FunctionComponent<SEOProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			metaData {
			meta {
				title
				tags {
				content
				name
				}
			}
			}
		}
	`);

	return (
		<Helmet>
			{data.metaData.meta.title && <title>{data.metaData.meta.title}</title>}
			{data.metaData.meta.tags?.map(({ name, content }: any) => <meta key={uniqueId()} name={name} content={content} />)}
		</Helmet>
	);
};
