/* global Content */
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import * as React from 'react';

import * as styles from './styles.module.scss';

interface ImageProps {
	image: Content.ImageObject;
}

export const Image: React.FunctionComponent<ImageProps> = ({ image }) => {
	const files = useStaticQuery(graphql`
		query {
			desktop: allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: CONSTRAINED)
						}
					}
				}
			},
			mobile: allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
						}
					}
				}
			}
		}
	`);
	const imageData = {
		desktop: getImageByName(files.desktop, image.imageName),
		mobile: getImageByName(files.mobile, image.imageName)
	};
	const source = (imageData.desktop && imageData.mobile) && withArtDirection(imageData.desktop, [
		{
			media: '(max-width: 767px)',
			image: imageData.mobile
		}
	]);

	if (!source) {
		throw new Error('This Image does not exist!');
	}

	return <GatsbyImage className={styles.heroImage} imgClassName={styles.heroImageInner} image={source} alt={image.altText ?? ''} />;
};

function getImageByName (files: any, name: string) {
	return getImage(files.edges.find((imageEntry: any) => imageEntry.node.name === name).node);
}
