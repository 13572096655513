import { graphql, PageProps, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { Hero } from '@components/Hero';
import * as Sections from '@components/sections';
import { SiteWrapper } from '@components/SiteWrapper';
import { uniqueId } from '@helper/uniqueId';

const Home: React.FunctionComponent<PageProps> = () => {
	const website = useStaticQuery(graphql`
	query {
		allContentData {
		  edges {
			node {
			  elemName
			  props
			}
		  }
		}
		footerData {
		  areas {
			headline
			links {
			  href
			  title
			}
		  }
		  socials {
			facebook
			instagram
		  }
		  operating {
			address
			name
		  }
		}
		heroData {
		  ctaText
		  headline
		  layout
		  image {
			imageName
		  }
		}
		navigationData {
		  menuLinks {
			href
			title
		  }
		  ctaText
		}
	  }
	`);

	return (
		<SiteWrapper
			data={{
				navigation: website.navigationData,
				footer: website.footerData
			}}
		>
			<Hero {...website.heroData} />
			{website.allContentData.edges.map(({ node }: { node: any; }) => {
				const { elemName, props } = node;
				const element = elemName in Sections ? Sections[elemName] : elemName;
				const propsWithKey = { key: `${elemName}-${uniqueId()}`, ...JSON.parse(props) };

				return React.createElement(element, propsWithKey);
			})}
		</SiteWrapper>
	);
};

export default Home;
