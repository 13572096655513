
import clsx from 'clsx';
import * as React from 'react';
import ReactPlayer from 'react-player';

import { Button } from '@components/Button';
import { WithImage, WithVideo } from '@components/Hero';
import { Wrapper } from '@components/Wrapper';

import { Image } from './Image';
import * as styles from './styles.module.scss';

// @todo make use of onClick
export const TwoColumns: React.FunctionComponent<WithImage | WithVideo> = ({ headline, text, ctaText, ...media }) => (
	<div className={styles.heroBackdrop}>
		<Wrapper>
			<div className={clsx(styles.heroContent, 'video' in media && styles.withMedia, 'image' in media && styles.withImage)}>
				{'video' in media &&
					<div className={styles.heroVideo}>
						<ReactPlayer
							css={{
								position: 'absolute'
							}}
							url={media.video.url}
							width="100%"
							height="100%"
						/>
					</div>
				}
				{'image' in media && <Image className={styles.heroImage} image={media.image} />}
				<div className={styles.heroText}>
					<h1>{headline}</h1>
					<p>{text}</p>
					{ctaText && <Button role="primary" type="button" inverted={true}>{ctaText}</Button>}
				</div>
			</div>
		</Wrapper>
	</div>
);
