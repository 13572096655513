import clsx from 'clsx';
import * as React from 'react';

import { Card, CardProps } from '@components/Card';
import { Wrapper } from '@components/Wrapper';
import { uniqueId } from '@helper/uniqueId';

import * as styles from './styles.module.scss';

interface CardsProps extends CardProps {
	headline: string;
	text: string;
}
interface CardsGridProps {
	headline: string;
	subline: string;
	text: string;
	cards: CardsProps[];

	id?: string;
	textCenter?: boolean;
	frame?: boolean;
}

export const CardsGrid: React.FunctionComponent<CardsGridProps> = ({ headline, subline, text, cards, id, textCenter, frame }) => (
	<Wrapper id={id}>
		{(subline || headline || text) &&
			<div className={styles.iconsGridHeader}>
				<div>
					{subline && <h3>{subline}</h3>}
					{headline && <h2>{headline}</h2>}
					{text && <p>{text}</p>}
				</div>
			</div>
		}
		<div className={styles.iconsGrid}>
			{cards.map(({ headline: cardHeadline, text: cardText, ...restProps }) => (
				<Card key={`${cardText}-${uniqueId()}`} frame={frame} {...restProps}>
					<h4 className={clsx(textCenter && styles.centered)}>{cardHeadline}</h4>
					<p className={clsx(textCenter && styles.centered)}>{cardText}</p>
				</Card>
			))}
		</div>
	</Wrapper>
);
