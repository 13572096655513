/**
 * Gives Information about current windowsize
 * @callback Callback
 * @returns {void}
 */
import * as React from 'react';

type Callback = (dimensions: {width: number; height: number; }) => void;

export function useWindowDimensions (callback: Callback): void {
	const handleResize = React.useCallback(() => {
		callback({ width: window.innerWidth, height: window.innerHeight });
	}, [callback]);

	React.useEffect(() => handleResize(), []);

	React.useLayoutEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
}
