import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { Button } from '@components/Button';
import { useGlobalAction } from '@hooks/useGlobalAction';

import * as styles from './styles.module.scss';

interface CtaAreaProps {
	headline: string;
	subline: string;
	ctaText: string;
	ctaCaption: string;
	imageName?: string;
	inverted?: boolean;

	onCLick? (): void;
}

export const CtaArea: React.FunctionComponent<CtaAreaProps> = ({ headline, subline, ctaText, imageName, ctaCaption, inverted }) => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
		}
	`);
	const source = imageName && files.allFile.edges.find((imageEntry: any) => imageEntry.node.name === imageName).node.childImageSharp.gatsbyImageData;
	return (
		<div className={clsx(styles.ctaAreaBackdrop, inverted && styles.inverted, source && styles.withImage)}>
			{source &&
				<GatsbyImage
					image={source}
					className={styles.ctaAreaImg}
					alt=""
				/>
			}
			<div className={clsx(styles.ctaAreaWrapper, inverted && styles.inverted)}>
				<div>
					{headline && <h2>{headline}</h2>}
					{subline && <h3>{subline}</h3>}
				</div>
				<Button type="button" inverted={inverted ?? false} role="primary" onClick={useGlobalAction()}>
					{ctaText}
				</Button>
				<div className={styles.ctaAreaButton}>
					{ctaCaption}
				</div>
			</div>
		</div>
	);
};
