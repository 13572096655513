/* global Content */
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

interface ImageProps {
	image: Content.ImageObject;
	className?: string;
	imgClassName?: string;
}

export const Image: React.FunctionComponent<ImageProps> = ({ image, className, imgClassName }) => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: CONSTRAINED)
						}
					}
				}
			}
		}
	`);
	const imageData = getImage(files.allFile.edges.find((imageEntry: any) => imageEntry.node.name === image.imageName).node);

	if (!imageData) {
		throw new Error('This Image does not exist!');
	}

	return <GatsbyImage className={className} imgClassName={imgClassName} image={imageData} alt={image.altText ?? ''} />;
};
