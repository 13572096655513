import * as React from 'react';
import ReactPlayer from 'react-player';

import { Button } from '@components/Button';
import { WithImage, WithVideo } from '@components/Hero';
import { useGlobalAction } from '@hooks/useGlobalAction';

import { Image } from './Image';
import * as styles from './styles.module.scss';

// @todo make use of onClick
export const BackgroundImage: React.FunctionComponent<WithImage | WithVideo> = ({ headline, text, ctaText, ...media }) => {
	const handleClick = useGlobalAction();

	return (
		<div className={styles.heroBackdrop}>
			<div className={styles.heroWrapper}>
				{'image' in media && <Image image={media.image} />}
				{'video' in media &&
					<div className={styles.heroVideo}>
						<ReactPlayer
							css={{
								position: 'absolute'
							}}
							url={media.video.url}
							width="100%"
							height="100%"
							controls={false}
							playing={true}
						/>
					</div>
				}
				<div className={styles.heroText}>
					<h1>{headline}</h1>
					<p>{text}</p>
					{ctaText && <Button role="primary" type="button" inverted={true} onClick={handleClick}>{ctaText}</Button>}
				</div>
			</div>
		</div>
	)
};
