/* global Content */
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import ReactPlayer from 'react-player';

import { uniqueId } from '@helper/uniqueId';

import * as styles from './styles.module.scss';
import { SublistElement } from './SublistElement';

interface ContentProps{
	headline: string;
	subline: string;
	text: string;
	link?: Content.LinkObject;
	id?: string;

	list?: {
		iconName: any;
		headline: string;
		text: string;
	}[];
}

export interface ImageProps extends ContentProps{
	image: Content.ImageObject;
}

export interface VideoProps extends ContentProps {
	video: Content.VideoObject;
}

export const ListElement: React.FunctionComponent<ImageProps | VideoProps> = ({ id, headline, subline, text, list, link, ...media }) => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: CONSTRAINED)
						}
					}
				}
			}
		}
	`);
	const source = 'image' in media && files.allFile.edges.find((imageEntry: any) => imageEntry.node.name === media.image.imageName).node.childImageSharp.gatsbyImageData;

	return (
		<div id={id} className={styles.listElement}>
			{'video' in media && <ReactPlayer url={media.video.url} width="auto" height="360px" /> }
			{('image' in media) && source &&
				<div className={clsx(styles.listElementWithCaption)}>
					<GatsbyImage image={source} alt={media.image.altText ?? ''} title={media.image.title} />
					{media.image.caption && <span>{media.image.caption}</span>}
				</div>
			}
			<div className={styles.listElementContent}>
				<div>
					<h4>{subline}</h4>
					<h3>{headline}</h3>
				</div>
				<p>{text}</p>
				{link?.href && link.text && <a href={link.href}>{link.text}</a>}
				{list?.map((props) => <SublistElement key={uniqueId()} {...props} />)}
			</div>
		</div>
	);
};
